import React, { useCallback, useReducer, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { throttle } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import useWindowSize from '../hooks/useWindowSize'
import useMouseMove from '../hooks/useMouseMove'
import { MIN_TABLET_MQ, INDEX_HEIGHT } from '../utils/presets'
import PortfolioIndex from './PortfolioIndex'
import { HTMLContent } from './Content'
import {
  MouseContext,
  MouseDispatch,
  MouseCordsContext,
  mouseReducer,
  NavDispatch,
  WaypointContext,
  waypointReducer,
  SizeContext
} from './MouseContext'
import UpArrow from '../img/icons/UpArrow.svg'
import LeftArrow from '../img/icons/LeftArrow.svg'
import DownArrow from '../img/icons/DownArrow.svg'
import RightArrow from '../img/icons/RightArrow.svg'

const WrapperStyle = styled(`div`)({
  transition: `transform 300ms ease-in-out`,
  maxHeight: `100vh`,
  overflowY: `scroll`,
  scrollSnapType: `y mandatory`
})

const ChildStyle = styled(`div`)({
  // paddingTop: `24px`,
  scrollSnapAlign: `start`,
  position: `relative`,
  cursor: 'none'
}, ({ fullHeight }) => {
  return {
    height: fullHeight && `100vh`
  }
})

const SiteHeader = styled.nav({
  minHeight: '44px',
  padding: '0',
  // display: 'flex',
  // width: '100%',
  // flexDirection: 'column',
  // alignContent: 'center',
  // alignItems: 'space-around',
  // textAlign: `center`,
  textAlign: `left`,
  paddingLeft: `1.5rem`,
}, ({ isPrivate }) => ({
  minHeight: isPrivate && `230px`
}))

const Nav = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'left',
  alignItems: 'flex-start',
  textAlign: 'left',
})

const NavLink = styled(Link)({
  display: `inline-block`,
  lineHeight: `2rem`,
  textDecorationLine: `none`,
  textUnderlineOffset: `0.25rem`,
  // borderBottom: `1px solid transparent`,
  // background: `linear-gradient(currentcolor,currentcolor) left 24px / 0px 1px no-repeat`,
  [`&:hover`]: {
    textDecorationLine: `underline`,
    // backgroundSize: `100% 0.5px`
  },
}, ({ isActive }) => ({
  // borderBottom: isActive && `1px solid #000`
  // backgroundSize: isActive && `100% 1.5px`
  textDecorationLine: isActive && `underline`
}))

const HomeLink = styled(NavLink)({
  textTransform: `uppercase`,
  marginTop: `0.5rem`,
  display: `inline-block`,
  [MIN_TABLET_MQ]: {
    display: `none`
  }
})

const PrivateNav = styled(`div`)({
  height: `150px`,
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'space-around',
  textAlign: `center`,
})

const InfoBody = styled.div({
  position: 'relative',
  maxWidth: '33rem',
  lineHeight: '24px',
  paddingBottom: '5rem',
  paddingTop: '24px',
  [MIN_TABLET_MQ]: {
    paddingTop: 0
  }
})

function whichArrow(direction) {
  switch (direction) {
    case 'prev':
      return LeftArrow
    case 'next':
      return RightArrow
    case 'up':
      return UpArrow
    case 'down':
      return DownArrow
    default:
      return ''
  }
}

const Cursor = styled.div({
  display: `none`,
  position: `fixed`,
  zIndex: `9999`,
  // fontSize: `32px`,
  // width: `1rem`,
  // height: `1rem`,
  // pointerEvents: `none`,
  // transform: `translate(-50%, -50%)`,
  // transformOrigin: `150% 150%`,
  width: `2rem`,
  height: `2rem`,
  pointerEvents: `none`,
  backgroundPosition: `center center`,
  backgroundSize: `contain`
}, ({direction, waypoint, target, isAbout}) => {
  // console.log("Cursor: direction: ", direction, " waypoint: ", waypoint, " target: ",target)

  // ARROW DIRECTION

  let arrow
  if (waypoint === "header" && target === "main") {
    // Down arrow when hovering over `main` region while at the `index` waypoint
    arrow = whichArrow("down")
  } else {
    // Up arrow when at footer waypoint, otherwise, we're at the `gallery` waypoint so respect the direction from state
    arrow = isAbout ? whichArrow("up") : whichArrow(waypoint === "footer" ? "up" : direction)

    // either I override the direction passed in here
    // and I show the next/prev arrows and control that as well
  }

  // SHOW OR HIDE ARROWS

  let display = `none`
  if (
    ((waypoint === "footer" && (target === "main" || target === "footer")) || // show arrows over "footer" region
    target === "main" || // show arrows over "main" region
    (waypoint === "main" && target !== "video" && target !== "menu"))  // hide arrows for video elements and menu navigation
    // && !isAbout // never show arrows on about page
  ) {
    display = `block`
  }

  if (isAbout && target === "content") { display = `none` }

  // console.log("Cursor display: ", display, arrow)

  return ({
    [MIN_TABLET_MQ]: {
      display,
      backgroundImage: `url(${arrow})`
      // '&:before': {
      //   content: `"${arrow}"`
      // },
    }
  })
})



const Wrapper = ({children, data, pathname}) => {
  // DATA
  let isPrivate
  if (data && data.markdownRemark) {
    const { markdownRemark: post } = data
    const { frontmatter: { private: privatePortfolio } } = post
    isPrivate = privatePortfolio
  }
  const isAbout = pathname.includes('/about') || pathname.includes('404')
  let description
  let info
  let hasInfo = false
  if (data && data.markdownRemark) {
    const { markdownRemark: page } = data
    const { index = [] } = page.frontmatter
    // for "Selected Works"
    if (index.length > 0 && index[0].project) {
      description = index[0].project.frontmatter.description
      info = index[0].project.html || description
    // for all other portfolios
    } else {
      description = page.frontmatter.description
      info = page.html || description
    }
    hasInfo = info && !isAbout
  }

  // DOM

  const wrapperRef = useRef()
  const headerRef = useRef()
  const mainRef = useRef()
  const footerRef = useRef()
  const cursorRef = useRef()

  // MQs
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {
    const delay = 2000
    setTimeout(() => {
      mainRef.current.scrollIntoView({ behavior: 'smooth' })
    }, delay)
  }, [pathname])

  // WINDOW

  const size = useWindowSize()

  // MOUSE

  const [mouseState, mouseDispatch] = useReducer(mouseReducer, [{ target: false }]);
  const direction = useMouseMove(cursorRef, wrapperRef, size, hasInfo, isAbout)

  // NAV DISPATCH HANDLERS

  const handleMainClick = () => {
    if (waypoint === "main" && (direction === "up" || isAbout)) {
      onNavDispatch("header")
    } else if (waypoint === "header") {
      onNavDispatch("main")
    }
  }

  // if not about or 404 page prevent handleMainClick from being fired
  const handleSiteHeaderClick = (event) => {
    if (!isAbout) {
      event.stopPropagation();
    }
  }

  const handleInfoAreaClick = () => {
    if (mouseState.target !== "content") {
      onNavDispatch("main")
    }
  }

  const onNavDispatch = (action) => {
    // console.log("onNavDispatch: ", action, waypoint)
    if (action === "header") {
      if (waypoint !== "header") {
        headerRef.current.scrollIntoView({ behavior: 'smooth' })
      } else {
        mainRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
    if (action === "main") {
      if (waypoint === "footer" || waypoint === "header") {
        mainRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    } else if (action === "footer") {
      if (waypoint !== "footer") {
        // waypointDispatch({ type: "footer" }) // show info as active on mobile
        footerRef.current.scrollIntoView({ behavior: 'smooth' })
      } else {
        mainRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  // WAYPOINTS

  const [waypoint, waypointDispatch] = useReducer(waypointReducer, "header");

  const onIndexItemClick = useCallback(() => {
    if (waypoint === "header") {
      mainRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [waypoint])

  const setWaypoint = (scrollTop) => {
    if (scrollTop < INDEX_HEIGHT) {
      // anything less then the height of the index means it's the index
      waypointDispatch({ type: "header"})
    } else {
      if (hasInfo) {
        const footerEnd = wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight === scrollTop
        const distanceToFooter = headerRef.current.scrollHeight + mainRef.current.scrollHeight
        const footerStart = distanceToFooter - 200 < scrollTop
        // 200 is an arbitrary value that estimates the white space between the main
        // and footer waypoints on mobile
        if (footerEnd || footerStart) {
          waypointDispatch({ type: "footer"})
        } else {
          waypointDispatch({ type: "main"})
        }
      } else {
        // if !index and !hasInfo then it's got to be the gallery
        waypointDispatch({ type: "main" })
      }
    }
  }

  const handleScroll = useMemo(() => {
    const throttled = throttle(e => setWaypoint(e.target.scrollTop), 250);
    return e => {
      e.persist();
      return throttled(e);
    };
  }, [hasInfo]);

  return (
    <WrapperStyle
      ref={wrapperRef}
      onScroll={handleScroll}
    >
      <ChildStyle
        ref={headerRef}
        fullHeight={false}
      >
        { !isPrivate && <PortfolioIndex {...size} pathname={pathname} onIndexItemClick={onIndexItemClick} /> }
        <SiteHeader
          onClick={!isTabletOrMobile && handleMainClick}
          onMouseEnter={() => mouseDispatch({ type: "enter", item: "main" })}
          onMouseLeave={() => mouseDispatch({ type: "exit" })}
          isPrivate={isPrivate}
        >
          { !isPrivate ?
            <Nav>
             <HomeLink
                to={`/`}
                isActive={pathname === "/"}
                onClick={handleSiteHeaderClick}
                onMouseEnter={() => mouseDispatch({ type: "enter", item: "menu" })}
                onMouseLeave={() => mouseDispatch({ type: "enter", item: "main" })}
              >{`Reto Schmid`}</HomeLink>
              <NavLink
                to={`/about/`}
                onClick={handleSiteHeaderClick}
                onMouseEnter={() => mouseDispatch({ type: "enter", item: "menu" })}
                onMouseLeave={() => mouseDispatch({ type: "enter", item: "main" })}
                isActive={pathname.includes('/about')}
              >About</NavLink>
            </Nav> :
            <PrivateNav>
              <div>{`Private portfolio`}</div>
              <div><HomeLink
                to={`/`}
                onClick={handleSiteHeaderClick}
                onMouseEnter={() => mouseDispatch({ type: "enter", item: "menu" })}
                onMouseLeave={() => mouseDispatch({ type: "enter", item: "main" })}
              >{`Go to website`}</HomeLink></div>
            </PrivateNav>
          }
        </SiteHeader>
      </ChildStyle>
      <ChildStyle
        ref={mainRef}
        onMouseEnter={() => mouseDispatch({ type: "enter", item: "main" })}
        onMouseLeave={() => mouseDispatch({ type: "exit" })}
        onClick={!isTabletOrMobile && handleMainClick}
        isAbout={isAbout}
        fullHeight
      >
        <MouseContext.Provider value={mouseState}>
          <MouseDispatch.Provider value={mouseDispatch}>
            <MouseCordsContext.Provider value={direction}>
              <NavDispatch.Provider value={onNavDispatch}>
                <WaypointContext.Provider value={waypoint}>
                  <SizeContext.Provider value={size}>
                    {children}
                  </SizeContext.Provider>
                </WaypointContext.Provider>
              </NavDispatch.Provider>
            </MouseCordsContext.Provider>
          </MouseDispatch.Provider>
        </MouseContext.Provider>
      </ChildStyle>
      { hasInfo &&
        <ChildStyle
          ref={footerRef}
          onClick={!isTabletOrMobile && handleInfoAreaClick}
          onMouseEnter={() => mouseDispatch({ type: "enter", item: "footer" })}
        >
          <InfoBody>
            <HTMLContent
              content={info || description}
              onMouseEnter={() => mouseDispatch({ type: "enter", item: "content" })}
              onMouseLeave={() => mouseDispatch({ type: "enter", item: "footer" })}
            />
          </InfoBody>
        </ChildStyle>
      }
      { !isTabletOrMobile && <Cursor ref={cursorRef} direction={direction} waypoint={waypoint} target={mouseState.target} isAbout={isAbout} hasInfo={hasInfo} /> }
    </WrapperStyle>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string
}

export default Wrapper
